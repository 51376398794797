<template>
<div>
    <video :srcObject.prop="stream" autoplay playsinline :muted="!audio"/>
    <br/>
    <button class="btn btn-primary mr-2" @click="start">Start</button>
    <button class="btn btn-primary" @click="stop">Stop</button>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('ffmpeg');
import { getUserMedia } from '@/services/mediautils';
// eslint-disable-next-line
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { sleep } from '@/services/utils';

export default {
  data() {
    return {
      stream: null,
      audio: false,
      closeInput: false,
      inputArray: [],
    }
  },
  async mounted() {
    this.stream = await getUserMedia({video:true, audio:true, definition:"low"});
    log.log("stream=", this.stream);
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    async stop() {
      log.log("stop");
      this.mediaRecorder.stop();
      this.ffmpeg?.exit();
    },
    async start() {
      // Init ffmpeg
      let ffmpeg = createFFmpeg({ 
        corePath: '../static/ffmpeg-core.js',
        log: true 
      });
      await ffmpeg.load();
      this.ffmpeg = ffmpeg;

      /*
      // simple test
      ffmpeg.FS('writeFile', 'test.mov', await fetchFile('../abby.mov'));
      await ffmpeg.run('-i', 'test.mov', 'test.mp4');
      log.log("result", ffmpeg.FS('readFile', 'test.mp4'));
      */

      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: 'video/webm;codecs=h264',
        videoBitsPerSecond : 3000000,
        sampleRate: 44100,
      });

      this.mediaRecorder.ondataavailable = (e) => {
        log.log("mediaRecorder dataavailable")
        this.inputArray.push(e.data);
      };

      window.prompt = async () => {
        if (this.inputArray.length > 0) {
          log.log("request input given");
          let top = this.inputArray[0];
          this.inputArray = this.inputArray.slice(1);
          return top;
        }
        return '';
      };

      this.mediaRecorder.start(1000);

      await sleep(4000);

      /*
      ffmpeg.FS('init', () => {
        log.log("request input");
        return Uint8Array('lol');
      }, (s) => { log.log(s); }
      , (s) => { log.log(s); });
      */
      let params = [ '-i',
        '-',
        '-vcodec',
        'copy',
        '-preset',
        'ultrafast',
        '-acodec',
        'aac',
        '-f',
        'hls',
        '-hls_time',
        '4',
        '-hls_flags',
        'append_list',
        '-hls_playlist_type',
        'event',
        'stream.m3u8' ];
      log.log("ffmpeg.run", params);
      ffmpeg.run(...params);
    }
  }
}
</script>

<style>

</style>